import { SelectionProvider } from '@saatva-bits/pattern-library.modules.selection'
import { FiltersProvider } from '@/contexts/filters'

import Layout from '@/layouts/Default'
import FilterContent from './partials/FilterContent/FilterContent'

import categoryContentConfig from './content'
import useFetchRecentlyViewedData from '@/hooks/useFetchRecentlyViewedData'

export default function CategoryView(props) {
    const {
        products,
        category,
        filterContextData,
        recommendationData,
        reviewList
    } = props

    const { recentlyViewedData, loading } = useFetchRecentlyViewedData()
    const productsData = [...products, ...recommendationData, ...recentlyViewedData]
    const CategoryContent = categoryContentConfig[category]?.content

    return (
        <Layout {...props} useExtendedContainer={true}>
            <SelectionProvider productsData={productsData} defaultInStock={true}>
                <div className="container--lg-extended">
                    <FiltersProvider contextData={filterContextData}>
                        <FilterContent products={products} category={category} />
                    </FiltersProvider>
                    {CategoryContent &&
                        <CategoryContent
                            category={category}
                            recommendationData={recommendationData}
                            reviews={reviewList}
                            recentlyViewedData={{
                                products: recentlyViewedData,
                                loading: loading
                            }}
                        />
                    }
                </div>
            </SelectionProvider>
        </Layout >
    )
}

