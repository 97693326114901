import React from 'react'
import PropTypes from 'prop-types'

import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'

import RightSetup from '@/components/RightSetup/RightSetup'
import ValuePropsSection from '@/components/ValuePropsSection/ValuePropsSection'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import SupportValueProps from '@/components/SupportValueProps'
import RecentViewedProductSlider from '@/components/RecentViewedProductSlider/RecentViewedProductSlider'

const FurnitureContent = ({ category, reviews, recentlyViewedData }) => {
    return (
        <>
            <RightSetup categoryName={category} />
            <ReviewSection reviews={reviews} />
            <ValuePropsSection configKey={category} background="transparent" />
            <SupportValueProps />
            <RecentViewedProductSlider
                dataSelector={'furniture-plp-recently-viewed'}
                recentlyViewedData={recentlyViewedData}
            />
            <FaqSection />
        </>
    )
}

FurnitureContent.propTypes = {
    category: PropTypes.string.isRequired
}

export default FurnitureContent
