import React from 'react'
import PropTypes from 'prop-types'
import ReviewSection from '@/components/ReviewSection'
import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'
import SupportValueProps from '@/components/SupportValueProps'
import RecentViewedProductSlider from '@/components/RecentViewedProductSlider/RecentViewedProductSlider'

const BundlesContent = ({ reviews, recentlyViewedData }) => {

    return (
        <>
            <ReviewSection reviews={reviews} />
            <SupportValueProps />
            <RecentViewedProductSlider 
                dataSelector={'bundles-plp-recently-viewed'} 
                recentlyViewedData={recentlyViewedData}/>
            <FaqSection />
        </>
    )
}

BundlesContent.propTypes = {
    reviews: PropTypes.array
}

export default BundlesContent