import React from 'react'
import PropTypes from 'prop-types'

import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'

import ValuePropsSection from '@/components/ValuePropsSection'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import SupportValueProps from '@/components/SupportValueProps'
import RecentViewedProductSlider from '@/components/RecentViewedProductSlider/RecentViewedProductSlider'

import ValuePropCards from './ValuePropCards/ValuePropCards'

const BeddingContent = ({ category, reviews, recentlyViewedData }) => {
    return (
        <>
            <ReviewSection reviews={reviews} />
            <ValuePropCards />
            <ValuePropsSection configKey={category} background="transparent" />
            <SupportValueProps />
            <RecentViewedProductSlider 
                dataSelector={'bedding-plp-recently-viewed'} 
                recentlyViewedData={recentlyViewedData} 
            />
            <FaqSection />
        </>
    )
}

BeddingContent.propTypes = {
    category: PropTypes.string.isRequired
}

export default BeddingContent
