import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useFiltersState, useFiltersData } from '@/contexts/filters'

import FiltersGroup from '../FilterGroup/FiltersGroup'
import FilterModal from '../FilterModal/FilterModal'
import DropdownPanel from '@/components/DropdownPanel/DropdownPanel'
import { Icon } from '@saatva-bits/pattern-library.components.icon'
import InStockSwitch from '../InStockSwitch/InStockSwitch'

import styles from './FilterSection.module.scss'
import { applyVariantFiltering } from '@/utils/filters'

const ClearButton = ({ className, onClick }) => (
    <button
        className={classNames(styles.clearButton, className)}
        onClick={onClick}
        data-selector="clearAllFilters"
    >
        Clear all
    </button>
)

const FilterSection = ({ totalResults, products, selectedFilters }) => {
    const filtersState = useFiltersState()
    const { sortOptions, filterOptions } = useFiltersData()
    const {
        selectedValues,
        sortByLabel,
        updateFilters,
        clearFilters
    } = filtersState

    const selectedValuesLength = selectedValues.length

    const handleSortChange = useCallback(event => {
        const value = event.currentTarget.value
        if (sortByLabel !== value) {
            const label = sortOptions.find(op => op.label === value)?.label
            label && updateFilters(['sorting'], [label], true)
        }
    }, [sortByLabel, sortOptions, updateFilters])

    const handleFilterChange = useCallback((event, filterKey) => {
        const actionType = event.selectedValues.includes(event.lastToggled) ? 'remove' : 'add'
        const newFilterVals = [...filtersState[filterKey].values]

        if (actionType === 'add') {
            newFilterVals.push(event.lastToggled)
        } else if (actionType === 'remove') {
            newFilterVals.splice(newFilterVals.indexOf(event.lastToggled), 1)
        }

        updateFilters([filterKey], [newFilterVals], true)
    }, [filtersState, updateFilters])

    const inStockSelectedFilters = selectedFilters.map((filter) => {
        if (filter.property === 'inStock') {
            return {
                ...filter,
                matchingValues: ['true']
            }
        }

        return {
            ...filter
        }
    })
    const filteredProducts = applyVariantFiltering(products, inStockSelectedFilters)
    const filteredProductsLength = filteredProducts.length
    const displayInStockSwitch = filterOptions.some(option => option.property === 'inStock') && filteredProductsLength > 0
    const resultsLabel = selectedValuesLength ? `${totalResults} results for:` : `${totalResults} results`

    return (
        <>
            <div className={`row ${styles.filterSection}`}>
                <div className={`col col--xs-6 col--lg-8 ${styles.filtersContainer}`}>
                    <div className="u-hidden--lg-down">
                        <FiltersGroup
                            filters={filterOptions}
                            products={products}
                            handleFilterChange={handleFilterChange}
                            displayInStockSwitch={displayInStockSwitch}
                        />
                    </div>
                    <div className="u-hidden--lg-up">
                        <FilterModal
                            filters={filterOptions}
                            products={products}
                        />
                        {displayInStockSwitch &&
                            <div className='u-paddingTop--md'>
                                <InStockSwitch handleFilterChange={handleFilterChange} />
                            </div>
                        }
                    </div>
                </div>
                <ul role="menu" className={`col col--xs-6 ${styles.sortingFilterContainer}`}>
                    {sortOptions.length > 0 && <DropdownPanel
                        label="Sort by"
                        title={sortByLabel}
                        stopPropagation={false}
                        titleClassName={styles.sortingDropdownTitle}
                        dropdownPanelClassName={styles.sortingDropdownPanel}
                    >
                        <ul role="menu">
                            {sortOptions.map(option => {
                                const buttonClassnames = classNames(styles.sortingOptionButton, {
                                    [styles.optionSelected]: option.label === sortByLabel
                                })
                                return (
                                    <li role="menuitem" key={option.value}>
                                        <button
                                            value={option.label}
                                            className={buttonClassnames}
                                            onClick={e => handleSortChange(e)}
                                        >
                                            {option.label}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </DropdownPanel>}
                </ul>
            </div>
            <div className={styles.filtersResults}>
                <div className={`${styles.totalResults} u-hidden--md-down`}>
                    {resultsLabel}
                </div>
                <div className="u-flexDisplay u-flexJustify--spaceBetween u-hidden--md-up">
                    <span className={styles.totalResults}>{resultsLabel}</span>
                    {selectedValuesLength > 1 ? <ClearButton onClick={clearFilters} /> : null}
                </div>
                <div className={styles.appliedFiltersContainer}>
                    {selectedValues.map(({ key, label, value }) => {
                        const newFilterVals = [...filtersState[key].values]
                        newFilterVals.splice(newFilterVals.indexOf(value), 1)

                        return (
                            <button
                                key={value}
                                className={styles.appliedFilterButton}
                                onClick={() => updateFilters([key], [newFilterVals], true)}
                                data-selector="removeFilter"
                            >
                                {label}
                                <Icon
                                    className={styles.buttonCloseIcon}
                                    name="close"
                                    alt="Delete Selection"
                                    description="Delete Selection"
                                    titleId="closeIcon"
                                />
                            </button>
                        )
                    })}
                </div>
                {selectedValuesLength > 1 ? <ClearButton className="u-hidden--md-down" onClick={clearFilters} /> : null}
            </div>
        </>
    )
}

FilterSection.propTypes = {
    totalResults: PropTypes.number.isRequired,
    products: PropTypes.array.isRequired,
}

export default FilterSection
