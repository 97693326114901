import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '@saatva-bits/pattern-library.components.icon'
import { useOnClickOutside } from '@saatva-bits/pattern-library.hooks.use-on-click-outside'

import styles from './DropdownPanel.module.scss'

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string?} props.dropdownPanelClassName
 * @param {string?} props.titleClassName
 * @param {boolean?} props.stopPropagation - should stop propagation event when clicking child components in panel?
 * @param {import('react').ReactNode} props.children
 */
const DropdownPanel = ({ label, title, dropdownPanelClassName, titleClassName, stopPropagation = true, children }) => {
    const containerRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    useOnClickOutside(containerRef, () => isOpen && setIsOpen(false))

    // Handle escape key
    useEffect(() => {
        function keydownHandler(e) {
            if (e.key === 'Esc' || e.key === 'Escape') {
                if (isOpen) {
                    setIsOpen(false)
                    const span = containerRef.current.querySelector('span')
                    span && setTimeout(() => span.focus(), 25)
                }
            }
        }

        document.addEventListener('keydown', keydownHandler)
        return () => document.removeEventListener('keydown', keydownHandler)
    }, [isOpen])

    // Close panel if user moves outside from it
    useEffect(() => {
        function keyupHandler(e) {
            if (e.key === 'Tab') {
                if (isOpen && !containerRef.current.contains(e.target)) {
                    setIsOpen(false)
                }
            }
        }
        document.addEventListener('keyup', keyupHandler)
        return () => document.removeEventListener('keyup', keyupHandler)
    }, [isOpen])

    const handleEnter = (e) => {
        if (e.key === 'Enter' || e.key === ' ' && !e.repeat) {
            e.preventDefault()
            // Focus first nondisabled element in the panel after it opens
            if (!isOpen) {
                const childEls = containerRef.current.querySelectorAll('input, button')
                const element = [...childEls].find(child => !child.disabled)
                element && setTimeout(() => element.focus(), 25)
            }
            setIsOpen(prevValue => !prevValue)
        }
    }

    const dropdownPanelClassNames = classNames(styles.dropdownPanel,
        {
            'u-hidden': !isOpen
        },
        dropdownPanelClassName
    )

    const hasLabel = Boolean(label)

    return (
        <li
            role="none"
            className={styles.dropdownContainer}
            ref={containerRef}
        >
            {hasLabel && <span className={styles.label}>{label}</span>}
            <span
                tabIndex={0}
                role="menuitem"
                aria-haspopup="menu"
                aria-expanded={isOpen}
                onKeyDown={handleEnter}
                onClick={() => setIsOpen(!isOpen)}
                data-selector={`dropdown-title-${title}`}
                className={classNames(styles.dropdownTitle, titleClassName, {
                    [styles.dropdownTitleBorder]: hasLabel
                })}
                aria-label={hasLabel ? `${label} ${title}` : title}
            >
                {title}
                <Icon
                    className={styles.chevronIcon}
                    alt=""
                    role="presentation"
                    name="expand"
                    aria-hidden="true"
                    description="Expand icon"
                    titleId="expandIcon"
                />
            </span>
            <div
                className={dropdownPanelClassNames}
                onClick={(e) => {
                    if (stopPropagation) {
                        e.stopPropagation()
                    }
                }}
            >
                {children}
            </div>
        </li>
    )
}

DropdownPanel.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleClassName: PropTypes.string,
    dropdownPanelClassName: PropTypes.string,
    stopPropagation: PropTypes.bool
}

export default DropdownPanel