import PropTypes from 'prop-types'
import styles from './Switch.module.scss'

/**
 * @param {Object} props
 * @param {boolean} props.checked
 * @param {func} props.onCheckedChange
 * @param {string} props.label
 * @param {string} props.id
 * @param {string?} props.labelClassName
 * @param {string?} props.switchClassName
 * @param {string?} props.role - aria role
 */
const Switch = ({
    checked,
    onCheckedChange,
    label,
    id,
    labelClassName,
    switchClassName,
    role
}) => {
    const state = checked ? 'checked' : 'unchecked'
    
    return (
        <div className={styles.container} role={role}>
            <label htmlFor={id} className={`${styles.label} ${labelClassName}`}>
                {label}
            </label>
            <button type="button" role="switch" aria-checked={checked} data-state={state} id={id} className={`${styles.background} ${switchClassName}`} onClick={() => onCheckedChange(!checked)}>
                <span data-state={state} className={styles.indicator} />
            </button>
        </div>
    )
}

Switch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onCheckedChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
    switchClassName: PropTypes.string,
    role: PropTypes.string
}

export default Switch
